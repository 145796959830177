<template>
    <div>
        <div v-if="isDesktop" class="main-header">
            <DesktopHeader />
        </div>
        <div v-else>
            <Header/>
        </div>
        <slot />
        <div v-if="isDesktop">
            <Footer />
        </div>
        <div v-else>
            <MobileFooter />
        </div>
        <PopupLogin />
    </div>
    
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile } from '@/helpers';
    import { TRIP_STATUS, TRIP_TYPE } from '@/vars';

    import DesktopHeader from '../components/desktop/Header.vue';
    import Header from '../components/mobile/Header.vue';
    import Footer from '../components/desktop/Footer.vue';
    import MobileFooter from '../components/mobile/Footer.vue';
    import PopupLogin from '../components/common/PopupLogin.vue';

    export default {
        name: 'LayoutDefault',
        components: {
            DesktopHeader,
            Header,
            Footer,
            MobileFooter,
            PopupLogin
        },
        data: () => ({
            isDesktop: false,
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        async mounted() {},
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            async home() {
                await this.$router.push({ name: 'home' });
            },
            async goTo(name) {
                if(this.user._id) {
                    await this.$router.push({ name });
                } else {
                    this.authorization()
                }
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async logout() {
                if(this.user._id) {
                    await store.dispatch('auth/logout');
                    store.commit('trips/CLEAR_ENTITIES');
                    store.commit('events/CLEAR_ALL');
                    await store.dispatch('trips/fetch', { filter: { type: TRIP_TYPE.TOUR_LEADER, status: TRIP_STATUS.ACTIVE } });
                    await this.$router.push({ name: 'home' });
                }
            }
        },
    }
</script>


<style lang="scss">
</style>
